import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, ElementsConsumer, } from "@stripe/react-stripe-js";
import * as cookie from "../../cookie";
import { withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as paymentService from "../../services/paymentService";
import * as posService from "../../services/posService";
import * as aspService from "../../services/aspService";
import * as cartService from "../../services/cartService";
import Topbar from "../topbar/topbar";
import Loader from "../loader/loader";
import "./css/payment.css";
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DialogButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  whiteSpace:'nowrap',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);


var payAmount = 0;
var tipAmount = 0;
var payerName = '';

const ThemeButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  paddingTop:'8px',
	  margin:'0 5px',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
  }))(Button);
 
const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
      fontSize:'16px',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);

class CheckoutForm extends React.Component {
	state = {
		loading:false,
		status:false,
		card:false,
		name:false,
		amount:false
	}
	handleSubmit = async (event) => {
		event.preventDefault();
		if(!this.state.status){
			return;
		}
		if (payAmount == 0) {
			alert('You can not pay 0');
			return;
		}
		const { stripe, elements, onSuccess, getBill , onPayAmountChange} = this.props;
		if (!stripe || !elements) {
			console.log("Stripe is not ready yet.");
			return;
		}
		this.setState({loading:true});

		

		const cardElement = elements.getElement(CardElement);
		const { error, token } = await stripe.createToken(cardElement, {});
		if (error) {
			console.log("[card error]", error);
		}

		let table = window.table;
		
		if (table.PaymentOption === '2') {  // open tab
			paymentService.capturePayment(payAmount).then(() => {
				paymentService.authorizeCard(token.id, payAmount, payerName).then(() => {
					this.setState({loading:false});
					onSuccess();
					
				});
			});
		} else { // normal payment
			getBill().then(bill=>{
				if(bill.changeTotal || bill.changeOwn){
					this.setState({loading:false});
					onPayAmountChange();
				} else {
					paymentService.makePayment(token.id, payAmount, payerName, tipAmount).then(() => {
						this.setState({loading:false});
						onSuccess();
					});
				}
			});
			
		}
	};

	onChange = (e) => {
		let status = (e.complete && this.state.name );
		let table = window.table;
		if (table.PaymentOption === '2') {
			status = (e.complete && this.state.name && this.state.amount);
		}
		this.setState({card:e.complete,status:status});
	};

	nameChange = (e) => {
		payerName = e.target.value;
		let name = (e.target.value !== '');
		let status = (name && this.state.card );
		let table = window.table;
		if (table.PaymentOption === '2') {
			status = (name && this.state.card && this.state.amount);
		}
		this.setState({name:name,status:status});
		console.log(e.target.value)
	}

	authAmount = (e) => {
		payAmount = parseFloat(e.target.value);
		let amount = (e.target.value !== '');
		let table = window.table;
		if (table.PaymentOption === '2') {
			let prevOrderAmount = parseFloat(window.table.TotalAmount);
			let myNewOrderValue = cartService.priceInfo.Gross - prevOrderAmount;// - parseFloat(window.currentBill.Own.BalanceAmount)
			let amt = parseFloat(e.target.value);
			if (myNewOrderValue > amt) {
				amount = false;
			} else {
				amount = true;
			}
		}


		let status = (amount && this.state.card && this.state.name);
		this.setState({amount:amount,status:status});
	}
	balanceValidation = (e) => {
		let prevOrderAmount = parseFloat(window.table.TotalAmount);
		let myNewOrderValue = cartService.priceInfo.Gross - prevOrderAmount;// - parseFloat(window.currentBill.Own.BalanceAmount)
		let amt = parseFloat(e.target.value);
		if (myNewOrderValue > amt) {
			payAmount = myNewOrderValue;
			e.target.value = this.__price(myNewOrderValue);
		}
	}
	getAmountField() {
		let table = window.table;
		if (table.PaymentOption === '2') {
			return (
				<div className="FormRow">
					<input type="number" pattern="[0-9]*" className="stripeTextField" id="authAmount" onKeyUp={this.authAmount} onBlur={this.balanceValidation} />
				</div>
			)
		} else {
			return '';
		}

	}

	getLabel() {
		let table = window.table;
		if (table.PaymentOption === '2') {
			return 'AUTHORIZE';
		} else {
			return 'MAKE PAYMENT';
		}
	}

	render() {
		const { stripe } = this.props;
		const CARD_OPTIONS = {
			iconStyle: "solid",
			hidePostalCode: true,
			style: {
				base: {
					iconColor: "#c4f0ff",
					color: "#222222",
					fontWeight: 500,
					fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
					fontSize: "16px",
					fontSmoothing: "antialiased",
					":-webkit-autofill": { color: "#fce883" },
					"::placeholder": { color: "#d4d4d4" },
				},
				invalid: {
					iconColor: "#cf3405",
					color: "#cf3405",
				},
			},
		};
		return (
			<form onSubmit={this.handleSubmit}>
				<fieldset className="FormGroup">
					{this.getAmountField()}
					<div className="FormRow">
						<input type="text" placeholder="Name" className="stripeTextField" id="payerName" autoComplete="off" spellCheck="false" onKeyUp={this.nameChange} />
					</div>
					<div className="FormRow">
						<CardElement options={CARD_OPTIONS} onChange={this.onChange} />
					</div>
				</fieldset>
				<div className="_btnHolder">
					<ThemeButton type="submit" disabled={!this.state.status}>{this.getLabel()}</ThemeButton>
				</div>
				<img src="/powered-by-stripe.png" className="stripe-p-logo" alt="stripe" />
				<Loader active={this.state.loading} />
					
				
			</form>
		);
	}
}

const InjectedCheckoutForm = (props) => {
	return (
		<ElementsConsumer>
			{({ elements, stripe }) => (
				<CheckoutForm elements={elements} stripe={stripe} onSuccess={props.onSuccess} getBill={props.getBill} onPayAmountChange={props.onPayAmountChange} />
			)}
		</ElementsConsumer>
	);
};

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement("script");
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
}

async function displayRazorpay(_amount, onSuccess, onReady,_this) {
	if (parseFloat(_amount) == 0) {
		alert('You can not pay 0');
		_this.setState({loading:false});
		return;
	}
	_amount = (parseFloat(_amount) + parseFloat(tipAmount)) * 100;

	const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

	if (!res) {
		alert("Razorpay SDK failed to load. Are you online?");
		return;
	}

	const result = await paymentService.createRozarPayOrder(_amount.toString());
	const { amount, id: order_id, currency } = result.data;

	const options = {
		key: posService.posData.RazorpayKeyID,
		amount: amount.toString(),
		currency: currency,
		name: window.manifest.name,
		description: "",
		image: window.manifest.logo,
		order_id: order_id,
		handler: async function (response) {
			posService.call(
				"UpdateOrderPayment",
				"post", {}, {}, {
				req: {
					APIToken: posService.posData.Code,
					OrderID: parseInt(cookie.get('orderid')),
					CustomerID: cookie.get('customerid'),
					Tip: tipAmount,
					PaymentGatewayName: "RazorPay",
					TransactionID: response.razorpay_payment_id,
					PaidAmount: payAmount + tipAmount,
				},
			}
			).then((res) => {
				//resolve(res.data);
				paymentService.paymentProcess(false).then(()=>{
					onSuccess();
				});
			});
		},
		
		prefill: {
			name: "",
			email: "",
			contact: "",
		},
		notes: {
			address: "",
		},
		theme: {
			color: "#61dafb",
		},
		modal: {
			"ondismiss": () => {
				paymentService.paymentProcess(false).then(()=>{
					
				});
			}
		},
		checkout:{
			"method": {
				"netbanking": "1",
				"card": "1",
				"upi": "1",
				"wallet": "0"
			}
		},
		method: {
			"netbanking": "1",
			"card": "1",
			"upi": "1",
			"wallet": "0"
		}
	};

	const paymentObject = new window.Razorpay(options);
	paymentObject.open();
	onReady();
}

class Payment extends Component {
	state = {
		totalCart: [],
		total: 0,
		own: 0,
		pay: 0,
		tip: 0,
		cc: [],
		code: window.manifest.CountryCode,
		type: undefined,
		toggleCart: false,
		currentBill: {
			Main: {
				TotalAmount: 0,
				TotalTaxAmount: 0,
				BalanceAmount: 0,
				PaidAmount: 0
			}
		},
		guestUser: '',
		loading:false,
		details:'hide',
		thanksDialog:false,
		amountChangeDialog:false,
		changeMsg:'',
		selectCode:false,
		filter:'',
		loginDialog:false,
		taxes:[{
            "TaxationID": 0,
            "BusinessUnitID": 0,
            "TaxCategoryName": "string",
            "PickUpCharge": 0,
            "DeliveryCharge": 0,
            "PreOrderCharge": 0,
            "DineInCharge": 10,
            "StoreID": 0
          },{
            "TaxationID": 0,
            "BusinessUnitID": 0,
            "TaxCategoryName": "string",
            "PickUpCharge": 0,
            "DeliveryCharge": 0,
            "PreOrderCharge": 0,
            "DineInCharge": 5,
            "StoreID": 0
          }],
        charges:[{
            "ChargesID": 0,
            "BusinessUnitID": 0,
            "ChargesName": "string",
            "IsPercentageOrFlat": false,
            "DeliveryCharge": 0,
            "FreeChargeOnPurchaseAbove": 0,
            "PickUpCharge": 0,
            "PreOrderCharge": 0,
            "DineInCharge": 20,
            "StoreID": 0
          }]
	};

	constructor() {
		super();
		this.countryCodeChange = this.countryCodeChange.bind(this);
		this.onPayChange = this.onPayChange.bind(this);
		this.custAmount = this.custAmount.bind(this);
		this.tipAmount = this.tipAmount.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
		this.getBill = this.getBill.bind(this);
		this.onPayAmountChange = this.onPayAmountChange.bind(this);
		this.ready = this.ready.bind(this);
	}

	getTotalTableCart() {
		let _this = this;
		posService.call("GetOrderItemDetailsWithAddon", "get", {}, {
			APIToken: posService.posData.Code,
			TableMasterID: cookie.get("tableNo"),
			CustomerID: 0,
			r: new Date().getTime(),
		}, {}).then((res) => {
			if (res.Result.OrderList !== undefined && res.Result.OrderList.PosOrder.OrderItems.Item !== undefined) {
				var _items = res.Result.OrderList.PosOrder.OrderItems.Item;
				if (!Array.isArray(_items)) {
					_items = [_items];
				}

				var sortItems = {};
				var pids = [];

				_items.forEach((item) => {
					if (item.Comments.__text !== undefined) {
						item.Comments = '';
					}
					if (sortItems[item.Cart_Identifier] === undefined) {
						pids.push(item.ProductID);
						sortItems[item.Cart_Identifier] = item;
					} else {
						sortItems[item.Cart_Identifier].Quantity = parseInt(sortItems[item.Cart_Identifier].Quantity) + parseInt(item.Quantity);
						sortItems[item.Cart_Identifier].SubtotalWOTax = parseFloat(sortItems[item.Cart_Identifier].SubtotalWOTax) + parseFloat(item.SubtotalWOTax);
					}
				});

				Object.keys(sortItems).forEach((identity) => {
					let obj = sortItems[identity];
					if (obj.Addons === "" || obj.Addons.__text === '') {
						obj.addonString = "";
					} else {
						if (Array.isArray(obj.Addons.Addon)) {
							var addons = [];
							Object.keys(obj.Addons.Addon).forEach((_i) => {
								let addon = obj.Addons.Addon[_i];
								var _aon = addon.ProductAddonName.split(":");
								if (addons[_aon[0]] === undefined) {
									addons[_aon[0]] = _aon[1];
								} else {
									addons[_aon[0]] += "," + _aon[1];
								}
								obj.SubtotalWOTax =
									(parseFloat(obj.UnitPrice) + parseFloat(addon.UnitPrice)) *
									parseInt(obj.Quantity);
								obj.UnitPrice =
									parseFloat(obj.UnitPrice) + parseFloat(addon.UnitPrice);
							});

							obj.addonString = "";
							Object.keys(addons).forEach((k) => {
								var v = addons[k];
								if (obj.addonString !== "") {
									obj.addonString += ",";
								}
								obj.addonString += k + ":" + v;
							});
						} else {
							//obj.addonString = obj.Addons.Addon.ProductAddonName;
							obj.SubtotalWOTax = (parseFloat(obj.UnitPrice) + parseFloat(obj.Addons.Addon.UnitPrice)) * parseInt(obj.Quantity);
							obj.UnitPrice = parseFloat(obj.UnitPrice) + parseFloat(obj.Addons.Addon.UnitPrice);
						}
					}
				});

				let _newCart = [];
				Object.keys(sortItems).forEach((identity) => {
					_newCart.push(sortItems[identity])
				})
				this.setState({ totalCart: _newCart });

			}
		});
	}

	__price(amount){
		return parseFloat(parseFloat(amount).toFixed(2))
	}


	getBill() {
		return new Promise((resolve, reject) => {
			let orderid = cookie.get('orderid');
			if(window.paymentLink){
				orderid = window.payOrderID;
			}
			posService.call('GetOrderAmountByOrderID', 'get', {}, {
				APIToken: posService.posData.Code,
				CustomerID: cookie.get('customerid'),
				OrderID: orderid,
				r: (new Date()).getTime()
			}, {}).then((res) => {
				if (typeof window.currentBill == 'undefined') {
					window.currentBill = res.Result;
					this.setState({ currentBill: res.Result })
					resolve({
						bill: window.currentBill,
						changeTotal: false,
						changeOwn: false
					});
				} else {
					let _currentBill = res.Result;
					var _res = {
						bill: _currentBill,
						changeTotal: false,
						changeOwn: false,
						prevBill: JSON.parse(JSON.stringify(window.currentBill))
					}
					if (JSON.stringify(_currentBill.Main) != JSON.stringify(window.currentBill.Main)) {
						_res.changeTotal = true;
					}
					if (JSON.stringify(_currentBill.Own) != JSON.stringify(window.currentBill.Own)) {
						_res.changeOwn = true;
					}
					window.currentBill = _currentBill;
					this.setState({ currentBill: _currentBill })
					resolve(_res);
				}
			});
		});
	}

	onPayChange = (e) => {
		switch (e.target.id) {
			case 'totalPay':
				this.setState({ type: e.target.id, pay: this.state.total });
				payAmount = this.state.total;
				break;
			case 'ownPay':
				this.setState({ type: e.target.id, pay: this.state.own });
				payAmount = this.state.own;
				break;
			case 'custPay':
				let el = document.getElementById('custAmount')
				let camount = el.value;
				if (camount === '') {
					camount =  this.__price(this.state.total);
					el.value = camount;
				}

				this.setState({ type: e.target.id, pay: camount });
				payAmount = camount;
				break;
				default: break;
		}

		

	}
	custAmount = (e) => {
		let el = e.target;
		let camount = el.value;
		if (camount === '') {
			camount = 0;
			//el.value = camount;
		} else {
		
			camount = this.__price(camount);
			if (camount > this.state.total) {
				camount = this.__price(this.state.total); 
				el.value = camount;
			}
		}

		this.setState({ pay: camount });
		payAmount = camount;
	}
	tipAmount = (e) => {
		tipAmount = e.target.value;
		if (tipAmount === '') { tipAmount = 0 }
		tipAmount = parseFloat(tipAmount);
		if(tipAmount < 0) {
			tipAmount = 0;
			e.target.value = '';
		}
		
		this.setState({ tip: tipAmount });
	}


	isOpenTab() {
		let table = window.table;
		if (table.PaymentOption === '2') {
			return 'hide';
		} else {
			let payData = window.getURLParameter('pay', window.location.search);
			if(payData !== null){
				return 'hide';
			} else {
				return '';
			}
		}
	}

	toggleTotalCart() {
		this.setState({ toggleCart: !this.state.toggleCart });
	}

	onSuccess() {
		this.setState({loading:true});
		cartService.getCart().then(cart => {
			this.setState({loading:false});
			this.setState({thanksDialog:true});
		});
	}

	isCart() {
		if (this.state.toggleCart) {
			return 'items active';
		} else {
			return 'items';
		}
	}

	pageTitle() {
		let table = window.table;
		if (table.PaymentOption === '2') {
			return (
				<h1 className="title">AUTHORIZE</h1>
			)
		} else {
			return (
				<h1 className="title">PAY NOW</h1>
			)
		}

	}

	currencySymbol(){
		let sym = window.currencySymbols[window.manifest.Currency];
        if(sym === undefined){
            sym = window.manifest.Currency;
        }
		return sym;
	}

	price(amount) {
		return this.currencySymbol() + ' ' + parseFloat(amount).toFixed(2)
	}

	ready(){
		this.setState({loading:false})
	}

	checkBill = (e) =>{
		this.setState({loading:true});
		this.getBill().then(bill=>{
			if(bill.changeTotal || bill.changeOwn){
				this.setState({loading:false});
				this.onPayAmountChange();
			} else {
				displayRazorpay(this.state.pay, this.onSuccess, this.ready, this);
			}
		});
	}

	razorPayBtn() {
		let table = window.table;
		let POS = posService.posData;
		if (table.PaymentOption !== '2') {
			if (POS.RazorpayKeyID !== '' && POS.RazorpayKeySecret !== '') {
				return (
					<div className="_btnHolder">
						<ThemeButton onClick={this.checkBill}>Other Payment Options</ThemeButton>
					</div>
				)
			} else {
				return '';
			}

		} else {
			return '';
		}
	}

	onPayAmountChange = (e)=>{
		let _this = this;
		return new Promise(function(resolve,reject){
			var _amount = parseFloat(parseFloat(window.currentBill.Main.BalanceAmount).toFixed(2)) + parseFloat(tipAmount)
			var _bal = parseFloat(window.currentBill.Main.BalanceAmount).toFixed(2);
			_this.setState({changeMsg:`Your cart value has been changed to ${_this.price(_bal)}, with tip ${_this.price(tipAmount)} your total ${_this.price(_amount)}.`,amountChangeDialog:true})
			resolve();
			/* dialogService.show({
				title: 'PAYMENT',
				body: `<p>Your cart value has been changed to ${_bal}, with tip ${tipAmount} your total ${_amount}.</p>`,
				actions: [{
					label: "OK",
					class: '',
					action: () => {
						dialogService.hide();
						resolve();
					}
				}]
			}); */
		});
	}

	getStripeModule() {
		//console.log('STRIPE=>', posService.posData);
		let POS = posService.posData;
		if (POS.StripeSecretKey !== '' && POS.StripePublishableKey !== '') {
			const stripePromise = loadStripe(posService.posData.StripePublishableKey);
			return <div className="stripe-holder">
				<img src="/Stripe-logo.png" className="stripe-logo" alt="stripe" />
				<Elements stripe={stripePromise}>
					<InjectedCheckoutForm onSuccess={this.onSuccess} getBill={this.getBill} onPayAmountChange={this.onPayAmountChange} />
				</Elements>
			</div>
		} else {
			return '';
		}
	}

	getCustomerMobileNo() {
		let code = cookie.get('customerCountryCode').toString();
		let number = cookie.get('customerMobile').toString();
		if (code != null) {
			if (number.search('0000') == 0) {
				return null;
			} else {
				return code + number;
			}
		} else {
			return null;
		}
	}

	renderOptions(props, option, snapshot, className) {
		return (
			<button {...props} className={className} type="button">
				{option.label}
			</button>
		);
	}


	getCountries() {
		return new Promise((resolve, reject) => {
			fetch('https://restcountries.eu/rest/v2/all').then((response) => {
				return response.json()
			}).then((data) => {
				resolve(data);
			}).catch(function (error) {
				console.log('Looks like there was a problem: \n', error);
				reject(error);
			});

		});
	}

	getCountryCodes() {
		return new Promise((resolve, reject) => {
            this.getCountries().then(data => {
                var cc = [];
                data.forEach(country => {
                    country.callingCodes.forEach(code => {
                        if (code !== ''){
                            let label = `+${code} | ${country.name}`;
                            cc.push({ 
                                label: label.toLowerCase(),
                                value: '+' + code, 
                                name: country.name ,
                                code:'+' + code
                            });
                        }
                            
                    });
                })
                this.setState({ cc: cc });
                resolve(cc);
            });
        });

	}

	isGuestUser() {
		if (this.getCustomerMobileNo() == null) {
			this.getCountryCodes().then(() => {
				this.setState({ guestUser: 'active', loginDialog:true })
			})

		}


	}

	countryCodeChange = (e) => {
		this.setState({ code: e });
	}


	leavePaymentPage() {
		this.setState({loginDialog:false});
		setTimeout(()=>{
			this.props.history.push(`/${window.app_code}/products`);
		},400);
	}

	codeFilter = e =>{
        this.setState({filter:e.target.value.toLowerCase()})
    }
	duplicateCart(pcart, id) {
		return new Promise(function (resolve, reject) {
			var _caritems = pcart;
			var cdata = [];
			_caritems.forEach(item => {

				var cartitem = {};
				cartitem['product_id'] = item.id;
				cartitem['quantity'] = item.cartQuantity;
				cartitem['specialInstruction'] = item.specialInstruction;

				if (item.varients != undefined) {
					let vids = [];
					item.varients.forEach(v => {
						vids.push(parseInt(v.id))
					})
					vids.sort();
					let _varients = [];
					vids.forEach(vid => {
						_varients.push({ id: vid, quantity: 1 })
					})

					cartitem.varients = _varients;
				}







				cdata.push(cartitem);
			});
			cartService.__addToCart(cdata, id).then(() => {
				resolve();
			}).catch(() => {
				reject();
			});




		});
	}

	updateCustomer() {
		this.setState({loading:true});
		let _this = this;
		//e.preventDefault();
		return new Promise((resolve, reject) => {
			let cc = this.state.code;
			let mobile = (document.querySelector('[name="mobile"]')).value;
			(document.querySelector('.mobile')).classList.remove('error');

			let validation = true;
			if (mobile != '' && mobile.length > 3 && mobile.length < 12) {
			} else {
				validation = false;
				(document.querySelector('.mobile')).classList.add('error');
			}
			if (validation) {
				posService.call('UpdateAnonymousCustomer', 'post', {}, {}, {
					req: {
						APIToken: posService.posData.Code,
						OrderID: cookie.get('orderid'),
						"CustomerID": cookie.get('customerid'),
						"Mobile": mobile,
						"PhoneCountryCode": cc,
						"CustomerName": cookie.get('customerName')
					}
				}).then((res) => {
					//var _prevCustomerid = customerid;
					if (res.Result.CustomerID == cookie.get('customerid')) {
						cookie.set('customerid', res.Result.CustomerID);
						cookie.set('customerCountryCode', cc);
						cookie.set('customerMobile', mobile);
						// done
						_this.updateCustomerComplete();

					} else {
						if (cartService.CART.length == 0) {
							cookie.set("customerid", res.Result.CustomerID);
							cookie.set('customerMobile', mobile);
							cookie.set('customerCountryCode', cc);
							cartService.getCart('UPDATE_BOTH_CART').then(() => {
								// done
								_this.updateCustomerComplete();
							})
						} else {
							var prevCart = JSON.parse(JSON.stringify(cartService.ecomCart));
							function changeUser() {
								_this.duplicateCart(prevCart, res.Result.CustomerID).then(function () {
									cookie.set("customerid", res.Result.CustomerID);
									cookie.set('customerMobile', mobile);
									cookie.set('customerCountryCode', cc);
									cartService.getCart('UPDATE_BOTH_CART').then(() => {
										// done
										_this.updateCustomerComplete();
									});
								}).catch(function () { });
							}
							changeUser();

						}



					}

				});

			}
		})

	}

	updateCustomerComplete() {
		this.setState({ guestUser: '', loading:false, loginDialog:false });
	}

	getItemClass(product){
		let cls =['item-row'];
		if(product.IsDeleted === '1'){
			cls.push('deleted');
		}
		return cls.join(' ');
	}

	handleOnPayment = (e)=>{
		e.preventDefault();
		e.stopPropagation();
		this.setState({thanksDialog:false})

		if(window.paymentLink){

			cookie.del('customerid');
			cookie.del('customerName');
			cookie.del('customerMobile');
			cookie.del('customerCountryCode');

			let manifest = window.manifest;
			var homeurl =  process.env.REACT_APP_HOME + '/' + manifest.BusinessUnitUrl;
			if (manifest.LitePWAEnable) {
				homeurl = process.env.REACT_APP_PWA + '/' + manifest.OrderingStoreSlug + '/landing'
			}
			window.location.href = homeurl;
		} else {
			if (window.getURLParameter('checkout', window.location.search) === 'true') {
				this.getBill().then(bill => {
					if (parseFloat(bill.bill.Main.BalanceAmount) == 0) {
						let feedbackLink = window.location.origin + '/' + window.app_code + '/feedback/?orderid='+cookie.get('orderid')+'&review=' + btoa([cookie.get('orderid'), cookie.get('tableNo'), cookie.get('customerCountryCode'), cookie.get('customerMobile'), cookie.get('customerName')].join('|'))
						posService.requestCheckout(feedbackLink).then(() => {

						});
					} else {
						this.props.history.push(`/${window.app_code}/products`);
					}
				});
			} else {
				this.props.history.push(`/${window.app_code}/products`);
			}
		}
	}

	setCode(code){
        this.setState({ code: code });
        this.setState({selectCode:false})
    }

	selectCode = e =>{
        var tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
        this.setState({selectCode:true});
        
        setTimeout(()=>{
           (document.querySelector('[name="select-c-code"]')).value = this.state.filter
        },600);
        
    }

	getChargeValue(charge){
        let applicable = true;
        if(charge.FreeChargeOnPurchaseAbove > 0 && this.state.currentBill.Main.TotalAmount >= charge.FreeChargeOnPurchaseAbove ){
            applicable = false;
        }
        if( applicable ) {
            if(charge.IsPercentageOrFlat){
                return ((this.state.currentBill.Main.TotalAmount * charge.DineInCharge)/100).toFixed(2)
            } else {
                return charge.DineInCharge;
            }
        } else {
            return 0;
        }
        
    }

    getTaxTotal(){
        let total = 0;
        this.state.taxes.map(tax=>(
            total += (this.state.currentBill.Main.TotalAmount * tax.DineInCharge)/100
        ));
        return total;
    }

    getChargeTotal(){
        let total = 0;
        this.state.charges.map(charge=>(
            total += this.getChargeValue(charge)
        ));
        return total;
    }

    getTotalAmount(){
        return this.state.currentBill.Main.TotalAmount + this.getTaxTotal() + this.getChargeTotal();
    }


	getTaxAndCharge(){
        return new Promise((resolve,reject)=>{
            aspService.getTax().then(_tax=>{
                aspService.getCharges().then(_charges=>{
                    this.setState({taxes:_tax,charges:_charges});
                    resolve();
                })
            })
        })
    }

	render() {
		return (
			<div className="payment">
				<Topbar {...this.props}/>

				{this.pageTitle()}

				<div className={"total-cart " + this.isOpenTab()}>
					<div className={this.isCart()}>
						{this.state.totalCart.map(product => (
							<div className={this.getItemClass(product)} key={product.Cart_Identifier}>
								<div className="product-info">
									<p className="product-name">{product.ProductItemName}</p>
									<p className="note">{product.Comments}</p>
									<p className="units">{this.price(product.UnitPrice)} x {product.Quantity}</p>
								</div>
								<div className="price">
									{this.price(product.SubtotalWOTax)}
								</div>
							</div>

						))}
					</div>
					
					<PrimaryButton  className="btn-sm view-cart" onClick={(e) => { this.toggleTotalCart() }}>{(this.state.toggleCart)?'Minimise':'View Details'}</PrimaryButton>
					<div className={"cart-price-details "+ this.state.details}>
						<div className="row">
							<label>Item Total</label>
							<p className="price">{this.price((parseFloat(this.state.currentBill.Main.TotalAmount) - parseFloat(this.state.currentBill.Main.TotalTaxAmount) - parseFloat(this.state.currentBill.Main.TotalChargeAmount)))}</p>
						</div>
						<div className="row">
							<label>Taxes</label>
							<p className="price">{this.price(this.state.currentBill.Main.TotalTaxAmount)}</p>
						</div>
						<div className="row">
							<label>Charges</label>
							<p className="price">{this.price(this.state.currentBill.Main.TotalChargeAmount)}</p>
						</div>
						<div className="row">
							<label>Total</label>
							<p className="price">{this.price(this.state.currentBill.Main.TotalAmount)}</p>
						</div>
						<div className="row paid">
							<label>Paid</label>
							<p className="price">-{this.price(this.state.currentBill.Main.PaidAmount)}</p>
						</div>
						<div className="row">
							<label>Tip</label>
							<p className="price">{this.price(this.state.currentBill.Main.Tip)}</p>
						</div>
						<div className="row">
							<label>Amount to be Paid</label>
							<p className="price">{this.price(this.state.currentBill.Main.BalanceAmount)}</p>
						</div>
					</div>

				</div>


				<div className={"pay-types " + this.isOpenTab()}>
					<div className="pay-type">
						<input type="radio" name="payType" id="totalPay" defaultChecked="checked" onChange={this.onPayChange} />
						<label htmlFor="totalPay"><span>Total Cart</span> {this.price(this.state.total)}</label>
					</div>
					<div className="pay-type">
						<input type="radio" name="payType" id="ownPay" onChange={this.onPayChange} />
						<label htmlFor="ownPay"><span>Own Cart</span> {this.price(this.state.own)}</label>
					</div>
					<div className="pay-type">
						<input type="radio" name="payType" id="custPay" onChange={this.onPayChange} />
						<label htmlFor="custPay"><span>Custom</span> {this.currencySymbol()} <input type="number" id="custAmount" pattern="[0-9]*" disabled={!(this.state.type === 'custPay')} onKeyUp={this.custAmount} /></label>
					</div>
				</div>
				<div className={"tip " + this.isOpenTab()}>
					<label>Tip</label>
					<input type="number" id="tipAmount" pattern="[0-9]*" onKeyUp={this.tipAmount} />
				</div>

				<div className="payableAmount">
					Payable Amount {this.price(parseFloat(this.state.pay) + parseFloat(tipAmount))}
				</div>

				{this.getStripeModule()}
				{this.razorPayBtn()}			  
				<Loader active={this.state.loading} />

				<Dialog
                    open={this.state.amountChangeDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-2-title"
                    aria-describedby="dialog-2-description"
                >
                    <DialogTitle id="dialog-2-title">{"PAYMENT"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-2-description">
						{this.state.changeMsg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{
							this.setState({amountChangeDialog:false});window.location.reload();}
						} >Ok</DialogButton>
                    </DialogActions>
                </Dialog>
                

                <Dialog
                    open={this.state.thanksDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-3-title"
                    aria-describedby="dialog-3-description"
                >
                    <DialogTitle id="dialog-3-title">{"PAYMENT"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-3-description">
						Thank you for payment.
						</DialogContentText>
						
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={this.handleOnPayment} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>

				<Dialog
                    open={this.state.loginDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-3-title"
                    aria-describedby="dialog-3-description"
                >
                    <DialogTitle id="dialog-3-title">{"LOGIN"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-3-description">
						Please update your mobile number.
						</DialogContentText>
						<div className="phone-number-row">
							<div className="field countryCode">
								<input onFocus={this.selectCode} value={this.state.code} name="countryCode" onChange={()=>{}}/>
							</div>
							<div className="field mobile">
								<input type="number" name="mobile" placeholder="Mobile" autoComplete="off" pattern="[0-9]*" spellCheck="false" />
							</div>
						</div>
                    </DialogContent>
                    <DialogActions>
						<DialogButton onClick={() => { this.leavePaymentPage() }}>CANCEL</DialogButton>
						<DialogButton onClick={() => { this.updateCustomer() }}>UPDATE</DialogButton>
                    </DialogActions>
				</Dialog>


				<Dialog fullScreen open={this.state.selectCode} className="select-code"  TransitionComponent={Transition}>
                    <div className="code-container">
                        <div className="code-header">
                        <div className="filter-field">
                            <input type="text" name="select-c-code" onKeyUp={this.codeFilter} placeholder="Country Code | Name" autoComplete="off" spellCheck="false" />
                        </div>
                        <IconButton color="inherit" onClick={()=>{this.setState({selectCode:false})}} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    
                    <List>
                        {this.state.cc.filter(country => country.label.includes(this.state.filter)).map(filtered => (
                            <ListItem key={filtered.code+filtered.name} button onClick={()=>{this.setCode(filtered.code)}}>
                                <ListItemText primary={filtered.code} secondary={filtered.name} />
                            </ListItem>
                        ))}
                    </List>
                    </div>
                </Dialog>



			</div>
		);
	}


	componentDidMount() {
		this.getTaxAndCharge();
		tipAmount = 0;
		this.isGuestUser();
		this.getStripeModule();
		this.getBill().then(bill => {
			this.setState({details:'', total: bill.bill.Main.BalanceAmount, own: bill.bill.Own.BalanceAmount, pay: bill.bill.Main.BalanceAmount });
			payAmount = this.__price(bill.bill.Main.BalanceAmount);

			let table = window.table;
			if (table.PaymentOption !== '2') {
				let el = document.getElementById('totalPay');
				el.checked = true;
			}
		});

		this.getTotalTableCart();
		this.setState({ type: this.props.match.params.type });

		this.props.history.listen((location, action) => {
			this.setState({ type: this.props.match.params.type });
		});
	}
}

export default Payment;
