import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as posService from "../../services/posService";
import * as cartService from "../../services/cartService";
import * as paymentService from "../../services/paymentService";
import * as aspService from '../../services/aspService';
import * as cookie from "../../cookie";
import Loader from "../loader/loader";
import Pusher from "pusher-js"

import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import "./css/cartsnap.css";

/* import { withRouter } from "react-router-dom";
const SomeComponent = withRouter((props) => <Cartsnap {...props} />); */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-400']),
	  backgroundColor: window.theme['primary-400'],
	  fontFamily:'inherit',
	  whiteSpace:'nowrap',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);

const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-400']),
	  backgroundColor: window.theme['primary-400'],
	  fontFamily:'inherit',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);

const SuccessButton = withStyles((theme) => ({
	root: {
		color: theme.palette.getContrastText('#1b8600'),
		backgroundColor:'#1b8600',
		fontFamily:'inherit',
		'&:hover': {
		backgroundColor: '#1b8600',
		},
		'&:disabled': {
		backgroundColor: '#d4d4d4',
		},
	},
}))(Button);



class Cartsnap extends Component {
	state = {
		count: 0,
		price: 0,
		class: "",
		cartlink: "",
		cartAction: "hide",
		cart: [],
		table: null,
		paymentOption: false,
		checkoutBTN: false,
		payBtn: "",
		reqCheckout: false,
		loading:false,
		checkoutDialog:false,
		notServedDialog:false,
		paymentProcessDialog:false,
		cancelItemDialog:false,
		cancelOrderDialog:false,
		reqCheckoutDialog:false,
		linkSentDialog:false,
		onlyRewardDialog:false,
		countryOptions:null,
		cc:[],
		code: window.manifest.CountryCode,
		selectCode:false,
        filter:'',
		taxes:[{"TaxationID":24,"BusinessUnitID":4174,"TaxCategoryName":"CGST","PickUpCharge":2.50,"DeliveryCharge":2.50,"PreOrderCharge":2.50,"DineInCharge":2.50,"StoreID":0},{"TaxationID":25,"BusinessUnitID":4174,"TaxCategoryName":"SGST","PickUpCharge":2.50,"DeliveryCharge":2.50,"PreOrderCharge":2.50,"DineInCharge":2.50,"StoreID":0}],
        charges:[{"ChargesID":25,"BusinessUnitID":4174,"ChargesName":"Service Charge","IsPercentageOrFlat":true,"DeliveryCharge":0.00,"FreeChargeOnPurchaseAbove":0.00,"PickUpCharge":0.00,"PreOrderCharge":0.00,"DineInCharge":10.00,"StoreID":0},{"ChargesID":26,"BusinessUnitID":4174,"ChargesName":"Convenience Charge","IsPercentageOrFlat":false,"DeliveryCharge":0.00,"FreeChargeOnPurchaseAbove":1000.00,"PickUpCharge":0.00,"PreOrderCharge":50.00,"DineInCharge":50.00,"StoreID":0}]
	};
	constructor() {
		super();
		this.handleButtons = this.handleButtons.bind(this);
	}

	isCheckout(status) {
		return (status) ? 'active' : ''
	}

	price(amount) {
		if (window.manifest === undefined) {
			return '';
		} else {
			let sym = window.currencySymbols[window.manifest.Currency];
			if(sym === undefined){
				sym = window.manifest.Currency;
			}
			return sym + ' ' + parseFloat(amount).toFixed(2)
		}

	}

	checkCart() {
		let topbar = document.querySelector('.topbar') != null;
		let pageElem = document.querySelector('.page');
		let cartSnap = document.querySelector('.cart-snap');
		if(topbar){
			pageElem.classList.add('top-gap');
		} else {
			pageElem.classList.remove('top-gap');
		}
		return new Promise((resolve) => {
			cartService.getCart().then((cartItems) => {
				let feedbackPage = this.isPage('feedback');
				let paymentPage = this.isPage('payment');
				if (cartItems.length > 0) {
					this.setState({ class: (feedbackPage) ? '' : "active", count: cartItems.length });
				} else {
					this.setState({ class: "", count: cartItems.length});
				}

				if(paymentPage){this.setState({ class: ""});}
				
				if(this.state.class === 'active'){
					cartSnap.classList.remove('hide');
					pageElem.classList.add('bottom-gap');
				} else {
					cartSnap.classList.add('hide')
					pageElem.classList.remove('bottom-gap');
				}
				this.setState({ cart: cartItems, price: cartService.priceInfo.subtotal });
				this.handleButtons();
				resolve();
			});
		})
	}

	isPage(page){
		return window.location.pathname.search(`/${window.app_code}/${page}`) === 0;
	}

	handleButtons() {
		let table = this.state.table;
		if (table == null) { return }
		let totalAmount = parseFloat(table.TotalAmount);
		let paidAmount = parseFloat(table.TotalPaidAmount);
		let remain = totalAmount - paidAmount;

		// show place order button or view cart ---
		if (this.isPage('cart')) {
			this.setState({ cartlink: "hide", cartAction: "" });
			let cartAction = false;
			this.state.cart.forEach(item => {
				if (!item.previous) {
					cartAction = true;
				}
			});
			this.setState({ cartAction: (cartAction) ? '' : 'hide' });
		} else {
			this.setState({ cartlink: "", cartAction: "hide" });
		}
		if(cookie.get('checkout') !== null){
			this.setState({ cartAction: "hide" });
		}
		//-----------------

		// understand make payment button 
		let PaymentRequired = (remain > 0);
		let isPaymentPage = this.isPage('payment')
		let itemsInCart = false;
		this.state.cart.forEach(item => {
			if (!item.previous) {
				itemsInCart = true;
			}
		});
		if (table.PaymentOption !== '2') { // for normal mode
			this.setState({ paymentOption: PaymentRequired, payBtn: (PaymentRequired) ? (isPaymentPage) ? '' : 'active' : '', checkoutBTN: !itemsInCart }); // show payBtn if not payment page
		} else { //for open tab mode
			this.setState({ paymentOption: false, payBtn: "", checkoutBTN: !itemsInCart });
		}
	}

	requestCheckout() {
		let feedbackLink = window.location.origin + '/' + window.app_code + '/feedback/?orderid='+cookie.get('orderid')+'&review=' + btoa([cookie.get('orderid'), cookie.get('tableNo'), cookie.get('customerCountryCode'), cookie.get('customerMobile'), cookie.get('customerName')].join('|'))
		posService.requestCheckout(feedbackLink).then(() => {
			this.setState({loading:false});
		});
	}

	getCountries() {
        return new Promise((resolve, reject) => {
            fetch('https://restcountries.eu/rest/v2/all').then((response) => {
                return response.json()
            }).then((data) => {
                resolve(data);
            }).catch(function (error) {
                console.log('Looks like there was a problem: \n', error);
                reject(error);
            });

        });
    }

    getCountryCodes() {
		return new Promise((resolve, reject) => {
            this.getCountries().then(data => {
                var cc = [];
                data.forEach(country => {
                    country.callingCodes.forEach(code => {
                        if (code !== ''){
                            let label = `+${code} | ${country.name}`;
                            cc.push({ 
                                label: label.toLowerCase(),
                                value: '+' + code, 
                                name: country.name ,
                                code:'+' + code
                            });
                        }
                            
                    });
                })
                this.setState({ cc: cc });
                resolve(cc);
            });
        });

	}

	choosePaymentMode() {
		this.getCountryCodes().then(cc=>{
			/* let options = '';
			cc.forEach(option=>{
				if(window.manifest.CountryCode === option.value){
					options += `<option value="${option.value}" selected>${option.label}</option>`;
				} else {
					options += `<option value="${option.value}">${option.label}</option>`;
				}
			}) */
			//this.setState({countryOptions:options})
			this.setState({'checkoutDialog':true});
		});
	}

	setCode(code){
        this.setState({ code: code });
        this.setState({selectCode:false})
    }

	selectCode = e =>{
        var tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
        this.setState({selectCode:true});
        
        setTimeout(()=>{
           (document.querySelector('[name="select-c-code"]')).value = this.state.filter
        },600);
        
	}
	codeFilter = e =>{
        this.setState({filter:e.target.value.toLowerCase()})
    }

	notServedYet() {
		this.setState({notServedDialog:true})
		/* dialogService.show({
			title: 'WARNING!',
			body: `<p>Your order is not served yet.<br>You can <b>Checkout</b> after the order is served.</p>`,
			actions: [{
				label: 'OK',
				class: '',
				action: () => { dialogService.hide(); }
			}]
		}) */
	}

	doCheckout = (e) => {
		
		/* 
		"1" --> New Order
		"2" --> Checkout
		"3" --> Order Accepted
		"4" --> checkout request already..
		"5" --> order served
		"6" --> Sent to Kitchen
		*/
		this.setState({loading:true});
		this.getTableData().then(table => {
			let totalAmount = parseFloat(table.TotalAmount);
			let paidAmount = parseFloat(table.TotalPaidAmount);
			let remain = totalAmount - paidAmount;
			let served = (table.Notification === '5');

			if (table.PaymentOption === '2') { // for open tab ...
				paymentService.capturePayment(remain).then(res => {
					if (served) {
						this.requestCheckout();
					} else {
						this.setState({loading:false});
						this.notServedYet();
					}
				});
			} else { // for normal mode.
				if (served) {
					if (remain === 0) {
						this.requestCheckout();
					} else {
						this.setState({loading:false});
						this.choosePaymentMode();
					}
				} else {
					this.setState({loading:false});
					this.notServedYet();
					//this.choosePaymentMode();
				}
			}
		});
	}

	checkOpenTap() {
		return new Promise((resolve, reject) => {
			if (window.table.PaymentOption === '2') {
				this.getTableData().then(() => {
					let prevOrderAmount = parseFloat(window.table.TotalAmount);
					let myNewOrderValue = cartService.priceInfo.Gross - prevOrderAmount;//parseFloat(window.currentBill.Own.BalanceAmount)
					
					if (window.table.IsAuth === 'true') {
						var balance = parseFloat(window.table.AuthAmount);
						if (balance >= myNewOrderValue) {
							resolve(true);
						} else {
							this.setState({loading:false});
							this.props.history.push(`/${window.app_code}/payment?short=${myNewOrderValue}&token=`+cartService.getUserToken())
							//openTap('short', cartService.priceInfo.Gross).then(() => { resolve(true) });
						}
					} else {
						this.setState({loading:false});
						this.props.history.push(`/${window.app_code}/payment?new=${myNewOrderValue}&token=`+cartService.getUserToken())
						//openTap('new', cartService.priceInfo.Gross).then(() => { resolve(true) });
					}
				});
			} else {
				resolve(true)
			}
		});
	}

	placeOrder =(e)=> {
		if(window.paymentOnProcess){
			this.setState({paymentProcessDialog:true});
			return;
		}
		this.setState({loading:true});
		this.checkOpenTap().then(() => {
			let products = [];
			let generalProduct = false;

			this.state.cart.forEach((item) => {
				if (!item.redeemableProduct) {
					generalProduct = true;
				}
				if (!item.previous) {
					var Addons = [];
					if (item.varients !== undefined) {
						item.varients.forEach(addon => {
							Addons.push({
								ProductCode: item.sku,
								"Quantity": addon.quantity,
								"AddonName": addon.RootName + ':' + addon.title
							});
						})

					}
					var instructionTypes = JSON.parse(item.specialInstruction).reduce(function (prev, cur) {
						prev[cur] = (prev[cur] || 0) + 1;
						return prev;
					}, {});

					var comments = [];
					(Object.keys(instructionTypes)).forEach((note) => {
						if (note !== '') {
							comments.push(note + ' (Qty:' + instructionTypes[note] + ')');
						}
					})

					products.push({
						ProductCode: item.sku,
						Quantity: item.cartQuantity,
						Cart_Identifier: item.cartProductIdentifier,
						Comments: comments.join(","),
						Comments_Json: item.specialInstruction,
						Addons: Addons,
						IsRedeemItem: item.redeemableProduct,
					});
				}
			});

			if (!generalProduct) {
				//alert("no general product");
				this.setState({loading:false, onlyRewardDialog:true});
				return;
			}

			let dt = new Date();
			let dateTime = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate()+' '+dt.getHours()+':'+dt.getMinutes()


			let OrderTaxes = [];
			this.state.taxes.forEach(tax=>{
				OrderTaxes.push( {
					"TaxName":tax.TaxCategoryName,
					"TaxPercentage":tax.DineInCharge,
					"TaxAmount":(this.state.price* tax.DineInCharge)/100
				 })
			});

			let OrderCharges = [];
			this.state.charges.forEach(charge=>{
				OrderCharges.push( {
					"ChargeName":charge.ChargesName,
					"ChargePercentage":(charge.IsPercentageOrFlat)?charge.DineInCharge:0,
					"ChargeAmount":this.getChargeValue(charge)
				 })
			});





			posService.call("SaveDiningOrderWithAddOn", "post", {}, {}, {
				req: {
					APIToken: posService.posData.Code,
					POsID: posService.posData.POSID,
					TableID: cookie.get("tableNo"),
					CustomerID: cookie.get("customerid"),
					child: products,
					OrderDateTime:dateTime,
					OrderTaxes:OrderTaxes,
					OrderCharges:OrderCharges
				},
			}).then((res) => {
				
				/* var data = {
						for:'order',
						orderid:res.Result.POSOrderID
					} */
				/* if(getURLParameter('pay') == null &&  getURLParameter('review') ==null){
						localStorage.setItem(BUID+'_POSOrderID',res.Result.POSOrderID);
					} */

				//var redirectURL =  '/order?for=' + btoa(JSON.stringify(data));
				cartService.getCart('UPDATE_POS_CART').then((cartItems) => {
					this.setState({loading:false});
					cookie.set('orderid', res.Result.POSOrderID);
					this.props.history.push(`/${window.app_code}/order/` + res.Result.POSOrderID+'/?token='+cartService.getUserToken());
				});

				this.getTableData().then((table) => {
					cartService.getCart().then(cart => {
						cookie.set('orderid', res.Result.POSOrderID);
						this.handleButtons();
					});
				});

			}).catch(function () {
				//$('[id="dtsPlaceOrder"]').removeClass('disabled');
			});

			
		}).catch(() => { })



	}

	getTableData() {
		return new Promise((resolve, reject) => {
			posService.call("GetTableList", "get", {}, {
				APIToken: posService.posData.Code,
				POSID: posService.posData.POSID,
				r: new Date().getTime(),
			}, {}).then((res) => {
				let tables = res.Result.Table1;
				if (!Array.isArray(tables)) {
					tables = [tables];
				}
				let tableno = cookie.get("tableNo");
				tables.forEach((table) => {
					if (tableno === table.TableMasterID) {
						this.setState({ table: table, reqCheckout: table.Notification === '2' });
						window.table = table;
						let _prevOrderID = cookie.get('orderid');
						if(_prevOrderID !== null && _prevOrderID !== table.ActiveOrderID){
							this.this.goToFeedback();
						} else {
							cookie.set('orderid', table.ActiveOrderID);
						}
						
					}
				});
				resolve(this.state.table);
			});
		});
	}

	observePusher(channel, key, action) {
		channel.bind(key, function (data) {
			console.log('PUSHER=>',key,"==== DATA=>",data);
			try {
				action(JSON.parse(data));
			} catch (error) {
				action(data);
			}
		}, { name: 'Pusher' });
	}

	isMyOrder(orderid) {
		return new Promise((resolve, reject) => {
			if (parseInt(orderid) === parseInt(cookie.get('orderid'))) {
				resolve();
			} else {
				reject();
			}
		})
	}

	isMyTable(tableNo) {
		return new Promise((resolve, reject) => {
			if (parseInt(tableNo) === parseInt(cookie.get('tableNo'))) {
				resolve();
			} else {
				reject();
			}
		})
	}

	goToFeedback(){
		cartService.clearCart().then(()=>{
			let review =btoa([cookie.get('orderid'),cookie.get('tableNo'),cookie.get('customerCountryCode'),cookie.get('customerMobile'),cookie.get('customerName'),cookie.get('customerid')].join('|'))
			this.props.history.push(`/${window.app_code}/feedback?orderid=${cookie.get('orderid')}&review=${review}&token=`+cartService.getUserToken());
		});
	}

	addPusher() {
		const pusher = new Pusher('bfb641c0e9acae7fbe8c');
		const channel = pusher.subscribe('MTCPOSSERVICE' + posService.posid);

		this.observePusher(channel, 'POSACCEPTCHECKOUT', (orderid) => {
			this.isMyOrder(orderid).then(() => {
				this.goToFeedback();
			});
		});
		this.observePusher(channel, 'POSCANCELITEM', (orderid) => {
			this.isMyOrder(orderid).then(() => {
				cartService.getCart('UPDATE_POS_CART').then((cartItems) => {
					if(cartService.prevPosCart !== null && cartService.prevPosCart.length > 0 && cartService.posCart.length === 0){
						this.setState({cancelItemDialog:true})
					}
				});
			});
		});

		this.observePusher(channel, 'PWAREORDERPLACED', (orderid) => {
			this.isMyOrder(orderid).then(() => {
				this.getTableData().then(() => {
					this.handleButtons();
				})
			});
		});

		this.observePusher(channel, 'POSCANCELORDER', (orderid) => {
			this.isMyOrder(orderid).then(() => {
				this.setState({cancelOrderDialog:true})
				/* dialogService.show({
					title: 'CANCELLED',
					body: `<p>Your order is cancelled. Please order again.</p>`,
					actions: [{
						label: 'OK',
						class: '',
						action: () => {
							cartService.clearCart().then(()=>{
								cookie.set('orderid','0');
								cartService.getCart('UPDATE_BOTH_CART').then(()=>{
									dialogService.hide();
								});
							})
						}
					}]
				}); */
			});
		});

		this.observePusher(channel, 'PAYMENTINITIATEDLOCK', (tableid) => {
			this.isMyTable(tableid).then(() => {
				window.paymentOnProcess = true;
			});
		});

		this.observePusher(channel, 'PAYMENTINITIATEDUNLOCK', (tableid) => {
			this.isMyTable(tableid).then(() => {
				window.paymentOnProcess = false;
			});
		});

		this.observePusher(channel, 'OPENTAPAUTHORISATION', (tableid) => {
			this.isMyTable(tableid).then(() => {
				this.getTableData().then(() => {
					
				});
			});
		});

		this.observePusher(channel, 'POSMOVETABLE', (data) => {
			this.isMyTable(data.OldTableID).then(() => {
				cookie.set("tableNo",data.NewTableID);
				this.getTableData().then(() => {
					
				});
			});
		});

		this.observePusher(channel, 'PWAGROUPCHECKOUT', (data) => {
			this.isMyTable(data.TableID).then(() => {
				this.getTableData().then(() => {
					cookie.set('checkout','true');
					this.handleButtons();
					this.setState({reqCheckoutDialog:true});
					let feedbackLink = window.location.origin + '/' + window.app_code + '/feedback/?orderid='+cookie.get('orderid')+'&review=' + btoa([cookie.get('orderid'), cookie.get('tableNo'), cookie.get('customerCountryCode'), cookie.get('customerMobile'), cookie.get('customerName')].join('|'))
					posService.sendReviewLink(feedbackLink);
				});
			});

		});

		this.observePusher(channel, 'POSORDERSERVED', (data) => {
			this.isMyOrder(data.OrderID).then(() => {
				this.getTableData().then(() => {
					this.handleButtons();
				})
			});
		});

	}

	countryCodeChange = (e) => {
        this.setState({ code: e });
    }

	renderOptions(props, option, snapshot, className) {
        return (
            <button {...props} className={className} type="button">
                {option.label}
            </button>
        );
    }

	handlePayCash = (e) =>{
		e.preventDefault();
		e.stopPropagation();
		this.requestCheckout();
		this.setState({checkoutDialog:false})
	}

	handlePayOnline =(e)=>{
		e.preventDefault();
		e.stopPropagation();
		this.setState({checkoutDialog:false});
		this.props.history.push(`/${window.app_code}/payment?checkout=true&token=`+cartService.getUserToken());
	}

	handleGetPayLink =(e)=>{
		e.preventDefault();
		e.stopPropagation();
		
		(document.querySelector('.__payLabel')).classList.add('hide');
		(document.querySelector('.pay-link-number')).classList.remove('hide');

		(document.querySelector('.__paycash')).classList.add('hide');
		(document.querySelector('.__payonline')).classList.add('hide');
		(document.querySelector('.__getpaylink')).classList.add('hide');

		(document.querySelector('.__getlink')).classList.remove('hide');
		(document.querySelector('.__cancel')).classList.remove('hide');
	}

	handleGetLink =(e)=>{
		e.preventDefault();
		e.stopPropagation();
		let countryCode = this.state.code;
		let mobileNumber = (document.querySelector('#pay_mobile')).value;
		window.__cc = countryCode;
		window.__mn = mobileNumber;
		if (mobileNumber !== '' && parseInt(mobileNumber).toString() === mobileNumber && mobileNumber.length > 3) {
			this.setState({checkoutDialog:false,loading:true});
			posService.call('AddCustomerWithMobile', 'post', {}, {}, {
				"acwm": {
					"APIToken": posService.posData.Code,
					"Name": '',
					"Mobile": mobileNumber,
					"PhoneCountryCode": countryCode,
					"BrowserID": '1234567890'
				}
			}).then((data) => {
				let payLink = window.location.origin + '/' + window.app_code + '/payment?pay=' + btoa([window.table.ActiveOrderID, cookie.get('tableNo'), countryCode, mobileNumber, ''].join('|'))
				posService.call('SendPaymentLink', 'post', {}, {}, {
					req: {
						"APIToken": posService.posData.Code,
						"OrderID": cookie.get('orderid'),
						"CustomerID": cookie.get('customerid'),
						"PhoneCountryCode": countryCode,
						"Mobile": mobileNumber,
						"PaymentLink": encodeURIComponent(payLink + '&r=1')
					}
				}).then((res) => {
					this.setState({loading:false})
					this.setState({checkoutDialog:false,linkSentDialog:true});
					/* dialogService.show({
						title: 'NOTIFICATION!',
						body: `<p>The payment link has been sent to ${countryCode}-${mobileNumber}.</p>`,
						actions: [{
							label: 'OK',
							class: '',
							action: () => {
								dialogService.hide();
							}
						}]
					}); */
				});
				////location.origin +'/'+app_code+'/'//?pay='+btoa([localStorage.getItem(BUID+'_POSOrderID'),ordertable,$('#pay_cc').val(),M,''].join('|'))
			}).catch(function (res) {
				this.setState({loading:false})
			});
		} else {
			this.setState({loading:false});
		}
	}
	handleCancel = (e) =>{
		(document.querySelector('#pay_mobile')).value = '';
		this.setState({checkoutDialog:false});
		e.preventDefault();
		e.stopPropagation();
		
		(document.querySelector('.__payLabel')).classList.remove('hide');
		(document.querySelector('.pay-link-number')).classList.add('hide');

		(document.querySelector('.__paycash')).classList.remove('hide');
		(document.querySelector('.__payonline')).classList.remove('hide');
		(document.querySelector('.__getpaylink')).classList.remove('hide');

		(document.querySelector('.__getlink')).classList.add('hide');
		(document.querySelector('.__cancel')).classList.add('hide');
	}

	getCashBtn(){
		if(!window.IsCashPaymentActive){
			return (
				<span className="__paycash" ></span>
			)
		} else {
			return (
				<DialogButton className="__paycash" onClick={this.handlePayCash} >Pay Cash</DialogButton>
			)
		}
		
	}
	getOnlinePayBtns(){
		if(window.onlinePayment){
			return (
				<React.Fragment>
					<DialogButton className="__payonline" onClick={this.handlePayOnline} >Pay Online</DialogButton>
					<DialogButton className="__getpaylink" onClick={this.handleGetPayLink} >Send Payment Link</DialogButton>
				</React.Fragment>
			)
		} else {
			return null;
		}
	}

	getChargeValue(charge){
        let applicable = true;
        if(charge.FreeChargeOnPurchaseAbove > 0 && this.state.price >= charge.FreeChargeOnPurchaseAbove ){
            applicable = false;
        }
        if( applicable ) {
            if(charge.IsPercentageOrFlat){
                return ((this.state.price * charge.DineInCharge)/100).toFixed(2)
            } else {
                return charge.DineInCharge;
            }
        } else {
            return 0;
        }
        
    }

    getTaxTotal(){
        let total = 0;
        this.state.taxes.map(tax=>(
            total += (this.state.price * tax.DineInCharge)/100
        ));
        return total;
    }

    getChargeTotal(){
        let total = 0;
        this.state.charges.map(charge=>(
            total += parseFloat(this.getChargeValue(charge))
        ));
        return total;
    }
    getTotalAmount(){
        return this.state.price + this.getTaxTotal() + this.getChargeTotal();
    }

	getTaxAndCharge(){
        return new Promise((resolve,reject)=>{
            aspService.getTax().then(_tax=>{
                aspService.getCharges().then(_charges=>{
                    this.setState({taxes:_tax,charges:_charges});
                    resolve();
                })
            })
        })
    }
	
	

	render() {
		return (
			<div className={"cartSnap " + this.state.class}>
				<div className="left-side">
					<div className={"itemInfo  " + this.isCheckout(!this.state.checkoutBTN)} >
						<span className="count">{this.state.count} Item(s)</span>
						<span className="price">{this.price(this.getTotalAmount())} </span>
					</div>

					<div className={"checkout " + this.isCheckout(this.state.checkoutBTN)}>
						<SuccessButton onClick={this.doCheckout} disabled={this.state.reqCheckout}>Checkout</SuccessButton>
					</div>
				</div>

					<div className={this.state.cartlink}>
						<Link to={`/${window.app_code}/cart/?token=`+cartService.getUserToken()}>
							<PrimaryButton>View Cart</PrimaryButton>
						</Link>
					</div>
					<div className={this.state.cartAction}>
					<PrimaryButton  onClick={this.placeOrder}>Place Order</PrimaryButton>
					
				</div>

				<Link to={`/${window.app_code}/payment/?token=`+cartService.getUserToken()} className={"payButton " + this.state.payBtn}>Make Payment</Link>
				<Loader active={this.state.loading} className="own-height" />


				<Dialog
                    open={this.state.checkoutDialog}
                    TransitionComponent={Transition}
                
                    aria-labelledby="dialog-1-title"
                    aria-describedby="dialog-1-description"
                >
                    <DialogTitle id="dialog-1-title">{"CHECKOUT"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-1-description" className="__payLabel">
						How would you like to checkout?
						</DialogContentText>
						<div className="pay-link-number hide">
							<p>Please provide the mobile number where you want to send the payment link.</p>
							<div className="phone-number-row">
								<div className="field countryCode">
									<input onFocus={this.selectCode} value={this.state.code} name="countryCode" onChange={()=>{}}/>
								</div>
								<div className="field mobile">
									<input id="pay_mobile" type="number" name="mobile" placeholder="Mobile" autoComplete="off" pattern="[0-9]*" spellCheck="false" />
								</div>
							</div>
						</div>
						<br/>
						<br/>
                    </DialogContent>
                    <DialogActions>
						{this.getOnlinePayBtns()}
						{this.getCashBtn()}
                        <DialogButton className="__getlink hide" onClick={this.handleGetLink} >Send Link</DialogButton>
                        <DialogButton className="__cancel" onClick={this.handleCancel} >Cancel</DialogButton>
                    </DialogActions>
				</Dialog>
				
				<Dialog
                    open={this.state.notServedDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-2-title"
                    aria-describedby="dialog-2-description"
                >
                    <DialogTitle id="dialog-2-title">{"WARNING"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-2-description">
						Your order is not served yet.<br/>You can Checkout after the order is served.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({notServedDialog:false})}} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>
				
				<Dialog
                    open={this.state.linkSentDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-3-title"
                    aria-describedby="dialog-3-description"
                >
                    <DialogTitle id="dialog-3-title">{"NOTIFICATION!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-3-description">
						The payment link has been sent to {window.__cc}-{window.__mn}.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({linkSentDialog:false})}} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>
				
				<Dialog
                    open={this.state.paymentProcessDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-4-title"
                    aria-describedby="dialog-4-description"
                >
                    <DialogTitle id="dialog-4-title">{"WARNING!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-4-description">
						Payment in process. Please wait.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({paymentProcessDialog:false})}} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>
				
				<Dialog
                    open={this.state.cancelItemDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-5-title"
                    aria-describedby="dialog-5-description"
                >
                    <DialogTitle id="dialog-5-title">{"CANCELLED"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-5-description">
						Your item is cancelled by restaurant.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{
							cartService.clearCart().then(()=>{this.setState({cancelItemDialog:false})})
						}} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>
				
				<Dialog
                    open={this.state.cancelOrderDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-6-title"
                    aria-describedby="dialog-6-description"
                >
                    <DialogTitle id="dialog-6-title">{"CANCELLED"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-6-description">
						Your order is cancelled. Please order again.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{
							cartService.clearCart().then(()=>{
								cookie.set('orderid','0');
								cartService.getCart('UPDATE_BOTH_CART').then(()=>{
									this.setState({cancelOrderDialog:false})
								});
							})
							}} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>
				
				<Dialog
                    open={this.state.reqCheckoutDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-7-title"
                    aria-describedby="dialog-7-description"
                >
                    <DialogTitle id="dialog-7-title">{"CHECKOUT"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-7-description">
							Checkout for table {window.table.TableName} has been requested.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{
							this.setState({reqCheckoutDialog:false});
							window.location.reload();
						}} >Ok</DialogButton>
                    </DialogActions>
                </Dialog>

				<Dialog fullScreen open={this.state.selectCode} className="select-code"  TransitionComponent={Transition}>
                    <div className="code-container">
                        <div className="code-header">
                        <div className="filter-field">
                            <input type="text" name="select-c-code" onKeyUp={this.codeFilter} placeholder="Country Code | Name" autoComplete="off" spellCheck="false" />
                        </div>
                        <IconButton color="inherit" onClick={()=>{this.setState({selectCode:false})}} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    
                    <List>
                        {this.state.cc.filter(country => country.label.includes(this.state.filter)).map(filtered => (
                            <ListItem key={filtered.code+filtered.name} button onClick={()=>{this.setCode(filtered.code)}}>
                                <ListItemText primary={filtered.code} secondary={filtered.name} />
                            </ListItem>
                        ))}
                    </List>
                    </div>
				</Dialog>
				
				<Dialog
                    open={this.state.onlyRewardDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-21-title"
                    aria-describedby="dialog-21-description"
                >
                    <DialogTitle id="dialog-21-title">{"WARNING"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-21-description">
						Please add item other than a reward to place the order
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({onlyRewardDialog:false})}} >Ok</DialogButton>
                    </DialogActions>
				</Dialog>
			</div>
		);
	}

	componentDidMount() {
		if (window.BUID !== undefined && window.app_code !== undefined && cookie.get('tableNo') !== null) {
			this.getTaxAndCharge();
			this.checkCart().then(() => {
				this.getTableData().then((table) => {
					this.handleButtons();
					if (table.PaymentOption === '2') {
						if (table.IsAuth === 'false' || parseFloat(table.AuthAmount) === 0) {
							if (parseInt(table.ActiveOrderID) === 0 && !window.opentapalert) {
								window.opentapalert = true;
								this.props.history.push(`/${window.app_code}/payment/opentab/?token=`+cartService.getUserToken());
							}
						}
					}
				});
			});


			cartService.Observe(() => {
				this.checkCart();
			});

			this.props.history.listen((location, action) => {
				this.handleButtons();
				this.checkCart();
			});

			this.addPusher();
		}
	}
}

export default Cartsnap;
