import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as posService from './services/posService';
import * as authService from './services/authService';
import * as cartService from './services/cartService';
import * as cookie from './cookie';
import { register, unregister } from './registerServiceWorker';

import './index.css';

window.URLParameter = (e) => {
    return decodeURIComponent((new RegExp("[?|&]" + e + "=([^&;]+?)(&|#|;|$)").exec(window.location.search) || [, ""])[1].replace(/\+/g, "%20")) || null
}



//==============
var agent = { browser: { name: null, version: null, v: null, userAgent: null, app: null, os: null }, mobile: false, pointlock: false };

//var nVer = navigator.appVersion;
var nAgt = navigator.userAgent;
var browserName = navigator.appName;
var fullVersion = '' + parseFloat(navigator.appVersion);
var majorVersion = parseInt(navigator.appVersion, 10);
var nameOffset, verOffset, ix;
agent.pointlock = 'pointerLockElement' in document ||
    'mozPointerLockElement' in document ||
    'webkitPointerLockElement' in document;

// In Opera, the true version is after "Opera" or after "Version"
if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
        fullVersion = nAgt.substring(verOffset + 8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
}
// In Chrome, the true version is after "Chrome" 
else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
}
// In Safari, the true version is after "Safari" or after "Version" 
else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
}
// In Firefox, the true version is after "Firefox" 
else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
}
// In most other browsers, "name/version" is at the end of userAgent 
else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
    }
}
// trim the fullVersion string at semicolon/space if present
if ((ix = fullVersion.indexOf(";")) != -1)
    fullVersion = fullVersion.substring(0, ix);
if ((ix = fullVersion.indexOf(" ")) != -1)
    fullVersion = fullVersion.substring(0, ix);

majorVersion = parseInt('' + fullVersion, 10);
if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
}
agent.browser.name = browserName;
agent.browser.version = fullVersion;
agent.browser.v = majorVersion;
agent.browser.app = navigator.appName;
agent.browser.userAgent = navigator.userAgent;
var OSName = "Unknown OS";
if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

agent.browser.os = OSName;
agent.mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);

//===============


window.paymentOnProcess = false;
window.theme = {};

// collect css theme color into js variables
let colors = document.getElementsByClassName('colors')[0];
Array.from(colors.children).forEach(el=>{
    let style = window.getComputedStyle(el);
    window.theme[el.className.replace('color-','')] = style.getPropertyValue('color')
});

// iPhone vh issue polyfills.
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// before app load collect app data..
posService.getCode().then((status) => {
    if (status) {
        posService.getTableStatus().then(table=>{
            if(posService.prevTable == null || posService.prevOrder == null){

            } else {
                if(posService.prevTable != table.TableMasterID || posService.prevOrder != table.ActiveOrderID){
                    let user = authService.getUser();
                    if(user != null){
                        cartService.clearCart().then(()=>{
                            if(window.URLParameter('tableno') == null){
                                let review =btoa([posService.prevOrder,posService.prevTable,cookie.get('customerCountryCode'),cookie.get('customerMobile'),cookie.get('customerName'),cookie.get('customerid')].join('|'))
                                window.location.href = `/${window.app_code}/feedback?orderid=${posService.prevOrder}&review=${review}`;
                                return;
                            }
                        });
                    } else {
                        //----------------
                    }
                }
            }
            
            cookie.set("orderid", table.ActiveOrderID);
            ReactDOM.render( 
                <BrowserRouter><App / ></BrowserRouter>,
                document.getElementById('root')
            );
        });
    } else {
        ReactDOM.render( 
            <BrowserRouter>
                <App / >
            </BrowserRouter>,
            document.getElementById('root')
        );
    }
});

if (process.env.NODE_ENV !== 'development') {
    register();
    fetch('../version.json?r=' + (new Date()).getTime(), {
        headers: { "content-type": "application/json" },
        "method": 'get',
        "async": true,
        "mode": 'cors',
    }).then(res => {
        return res.json();
    }).then(version => {
        let v = cookie.get('resto_version');
        if (v !== null && v !== version.version) {
            alert('update found');
            unregister();
        }
        cookie.set('resto_version', version.version);
    });

}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals   <React.StrictMode></React.StrictMode>
reportWebVitals();