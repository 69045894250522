import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/common/protectedRoute';

import Categories from './components/categories/categories';
import Products from './components/products/products';
import Product from './components/product/product';
import Login from './components/login/login';
import Scan from './components/scan/scan';
import Cart from './components/cart/cart';
import Order from './components/order/order';
import Payment from './components/payment/payment';
import Cartsnap from './components/cartsnap/cartsnap';
import Feedback from './components/feedback/feedback';
import Dialog from './components/dialog/dialog'; 
import './App.css';

/* 
const Categories = lazy(()=>{ import('./components/categories/categories')});
const Products = lazy(()=>{ import('./components/products/products')});
const Product = lazy(()=>{ import('./components/product/product')});
const Login = lazy(()=>{ import('./components/login/login')});
const Scan = lazy(()=>{ import('./components/scan/scan')});
const Cart = lazy(()=>{ import('./components/cart/cart')});
const Order = lazy(()=>{ import('./components/order/order')});
const Payment = lazy(()=>{ import('./components/payment/payment')});
const Cartsnap = lazy(()=>{ import('./components/cartsnap/cartsnap')});
const Feedback = lazy(()=>{ import('./components/feedback/feedback')}); */



function App() {
	return (
		
		<div className="main">
			<div className="page">
				<Switch>
					<ProtectedRoute path={`/:appcode/product/:id`} component={Product} />
					<ProtectedRoute path={`/:appcode/products/:id`} component={Products} />
					<ProtectedRoute path={`/:appcode/products`} component={Products} />
					<ProtectedRoute path={`/:appcode/payment/:type`} component={Payment} />
					<ProtectedRoute path={`/:appcode/payment`} component={Payment} />
					<ProtectedRoute path={`/:appcode/categories`} component={Categories} />
					<ProtectedRoute path={`/:appcode/cart`} component={Cart} />
					<ProtectedRoute path={`/:appcode/order/:id`} component={Order} />
					<Route path={`/:appcode/login`} component={Login} />
					<Route path={`/:appcode/scan`} component={Scan} />
					<Route path={`/:appcode/feedback`} component={Feedback} />
					<ProtectedRoute path={`/:appcode`} component={Products} />
					<ProtectedRoute path={`/scan`} component={Scan} />
					<ProtectedRoute path={`/`} component={Scan} />
				</Switch>
			</div>
			<div className="cart-snap">
				<ProtectedRoute path='/:appcode/*' component={Cartsnap} />
			</div>
			<div className="dialog-container">
				<Dialog />
			</div>
		</div>

	);
}

export default App;
